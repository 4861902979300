import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { triggerTurboStream } from '../utils/trigger_turbo_stream';
import { debounce } from 'lodash-es';

export default class extends Controller {
  static values = { url: String };
  private urlValue!: string;
  private debouncedFetchResults = debounce(this.triggerFetch, 350);

  connect() {
    super.connect();
    const input = this.element as HTMLInputElement;
    input.value = this.initialInputValue;
  }

  fetchResults(event: Event) {
    const input = event.target as HTMLInputElement;
    this.debouncedFetchResults(input.value);
  }

  private triggerFetch(query: string) {
    const url = this.prepareUrl(query);
    void triggerTurboStream({
      src: url,
      history: 'replace',
      forceReplace: true,
    });
  }

  private prepareUrl(query: string) {
    const url = new URL(this.urlValue || document.location.href);
    query === ''
      ? url.searchParams.delete('query')
      : url.searchParams.set('query', query);
    return url;
  }

  get initialInputValue(): string {
    const url = new URL(document.location.href);
    return url.searchParams.get('query') ?? '';
  }
}
